import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const LightCamera = () => {
  return (
    <div className="container">
      <Helmet>
        <title> Shoya Light & Camera </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Shoya Light & Camera" />
        <meta
          name="twitter:image"
          content="https://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div id="shoyalc" />
      <div className="articleList">
        <p>
          <a
            href="https://vimeo.com/701901571"
            target="__blank"
            rel="noopener noreferrer"
          >
            The Zipliner{" "}
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/462237055/14d4947cf7"
            target="__blank"
            rel="noopener noreferrer"
          >
            Ocean 2 Ocean
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/430291373"
            target="__blank"
            rel="noopener noreferrer"
          >
            Black Like Meow
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/https://vimeo.com/465513624/e839828fe1"
            target="__blank"
            rel="noopener noreferrer"
          >
            XOTX-Tropico
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/346187257"
            target="__blank"
            rel="noopener noreferrer"
          >
            Dennis Rodman & His 50 Siblings{" "}
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/688246835"
            target="__blank"
            rel="noopener noreferrer"
          >
            Mouthpiece{" "}
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/555688476"
            target="__blank"
            rel="noopener noreferrer"
          >
            Tia's Surprise{" "}
          </a>
        </p>
        <p>
          <a
            href="https://vimeo.com/528599178"
            target="__blank"
            rel="noopener noreferrer"
          >
            So Long, My Friends!{" "}
          </a>
        </p>
      </div>

      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020</p>
      </div>
    </div>
  )
}

export default LightCamera
